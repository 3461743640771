import { Component, OnInit } from '@angular/core';
import { BlogFilterService, BlogListService } from '../../_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogfilterRes : any;
  bloglistRes : any;
  blogs: any = [];
  loader = true; /* Loader */
  searchKey : string = '';
  current_page: any;
  next_page: any;
  prev_page:any;
  max:any;
  throttle = 100;
  scrollDistance = 1;
  page: any = "1";
  max_page: string = "1";
  selector: string = '.search-results';
  blog_loader = false;
 
  constructor( private blogfilterService : BlogFilterService,  private bloglistService :  BlogListService) { }

  ngOnInit() {
    
    this.loadBlogFilterService();
    // this.loadBlogListService();  
    this.BlogListService();    
  }
  
  onScrollDown () {
    if(this.page<=this.max_page) {
      // var pageAdd = parseInt(this.page)+1;
      this.page = parseInt(this.page)+1;
      this.BlogListService();
    }
  }
  
  getSearchInputValue() {    
    this.loader = true; /* Loader */      
    this.bloglistService.getBlogSearchService(this.searchKey).pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
      this.bloglistRes = res.data;   
      this.loader = false; /* Loader */  
      }
    });
  }

  loadBlogFilterService() {    
    this.blogfilterService.getBlogFilterService().pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
      this.blogfilterRes = res.data;        
      }
    });
  }
  loadBlogListService() {
    this.bloglistService.getBlogListService().pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
      this.bloglistRes = res.data; 
      this.loader = false; /* Loader */  
      this.current_page = parseInt(this.bloglistRes.current_page);
      this.next_page =this.current_page +1;
      this.prev_page =this.current_page -1;
      this.max = this.bloglistRes.max_page;
      
        
      }
    });
  }
  
  BlogListService() {
    let pageid = this.page;
    if(pageid=="1") {
      this.loader = true; /* Loader */ 
    } else {
      this.blog_loader = true;
    }
    this.bloglistService.getBlogListService(pageid).pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
        // this.bloglistRes = res.data; 
        this.loader = false; /* Loader */  
        this.blog_loader = false;
        /*this.current_page = parseInt(this.bloglistRes.current_page);
        this.next_page =this.current_page +1;
        this.prev_page =this.current_page -1;
        this.max = this.bloglistRes.max_page;*/
        this.blogs = this.blogs.concat(res.data.blog_details);
        this.max_page = res.data.max_page;
      }
    });
  }
  
  getBlogArchivesService(blogArchivesid) {
    this.loader = true; /* Loader */ 
    this.bloglistService.getBlogArchivesService(blogArchivesid).pipe(first()).subscribe((res:any) => {    
      if(res.status == "true"){ 
        this.bloglistRes = res.data;  
        this.loader = false; /* Loader */  
      }
    });
  }

}
