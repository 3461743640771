import { Component, OnInit } from '@angular/core';
import { Title ,Meta} from '@angular/platform-browser'; 
import { ActivatedRoute } from '@angular/router';
import { BlogListService } from '../../_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {
  blogPageid: string;
  blogDetailRes: any;
  loader = true; /* Loader */
  constructor(private route: ActivatedRoute, private blogdetailService : BlogListService, private meta:Meta, private titleService: Title) { 
  
   /*this.meta.addTags([
          { name: 'og:locale', content: 'en_US' },
          { name: 'og:title', content: 'The best ways to end up with successful guest posting' },
          { name: 'og:og:image', content: 'http://192.168.2.71/k2bindia/wp-content/uploads/2019/08/logo_k2b.png' },
          { name: 'og:url', content: 'http://192.168.2.113:4200/blog/the-best-ways-to-end-up-with-successful-guest-posting' },
          { name: 'og:type', content: 'website' },
          { name: 'og:description', content: 'K2B Solutions is a leading web design and mobile apps development company in india. They offers web and mobile apps development, digital marketing services at very affordable cost.' }
          
        ]);*/
        
        
        this.meta.updateTag({ name: 'og:locale', content: 'en_US' });
        this.meta.updateTag({ name: 'og:title', content: 'The best ways to end up with successful guest posting' });
        this.meta.updateTag({ name: 'og:image', content: 'http://192.168.2.71/k2bindia/wp-content/uploads/2019/08/logo_k2b.png' });
        this.meta.updateTag({name: 'og:url', content: 'http://192.168.2.113:4200/blog/the-best-ways-to-end-up-with-successful-guest-posting' });
        this.meta.updateTag({ name: 'og:type', content: 'website' });
        this.meta.updateTag({ name: 'og:description', content: 'K2B Solutions is a leading web design and mobile apps development company in india. They offers web and mobile apps development, digital marketing services at very affordable cost.' });
        
        
     }

  ngOnInit() {

    this.blogPageid = this.route.snapshot.paramMap.get('blogPageid');
    
    this.loadBlogDetailService();
    
  }

  loadBlogDetailService() {
    
    this.blogdetailService.getBlogDetailService(this.blogPageid).pipe(first()).subscribe((res:any) => {  
    
      if(res.status == "true"){ 
      this.blogDetailRes = res.data;   
      this.loader = false; /* Loader */  
      }
    });
  }

}
