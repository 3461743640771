import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CareersService } from '../../../_services';
import { first } from 'rxjs/operators';
import * as $ from 'jquery';
import { MustMatch } from '../../../_helpers/must-match.validator';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
	
	careerForm: FormGroup;
    submitted = false;
	careerRes : any;
	carRes : any;
  loader = true; /* Loader */
	loading : any;
	code : any; 
	active : any;

  constructor(private formBuilder: FormBuilder, private careerService: CareersService) { }

  ngOnInit() {

  	/*interactivity*/
        function followMouse() {
          let left = 0;
          let top = 0;
          $(document).mousemove(function(event) {
            left = Math.ceil(event.pageX / 80);
            top = Math.ceil(event.pageY / 80);

            $(".mouse_follow").css({
              transform: "translate(" + left + "px," + top + "px)"
            });
            $(".mouse_follow_left").css({
              transform: "translateX(" + left + "px)"
            });
            $(".mouse_follow_top").css({
              transform: "translateY(" + left + "px)"
            });
          });
        }
        $(document).ready(function () {
          followMouse();
        })
        /*interactivity*/
          
	this.generate_code();
		
	const pcode = localStorage.getItem('code');
	
	this.loadourcareerService();
		
	
	 this.careerForm = this.formBuilder.group({
		  name: ['',Validators.required],      
		  email: ['',[Validators.required, Validators.email]],
		  phone:[''],
		  qualification: [''],
		  experience: [''],
		  position: [''],
		  profile: ['',Validators.required],
		  code: ['',Validators.required]
	  }, {
            validator: MustMatch('code',pcode)
        });
	
	
  }
  
  currentDD(event){  	
  	$('.current_opening_desc').removeClass("active");  	
	event.parentElement.nextSibling.classList.add('active');
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.careerForm.get('profile').setValue(file);
      
      $('.filename').html(file.name);
    }
  }
  
  // convenience getter for easy access to form fields
    get f() { return this.careerForm.controls; }
    
    oncareerSubmit() {
    	this.loading = 'load_btn';
        this.submitted = true;

        // stop here if form is invalid
        if (this.careerForm.invalid) {
        	const thiss = this;
        	setTimeout(function(){
    			thiss.loading = '';
        	}, 800);
            return;
        }      
        
        const formData = new FormData();
        formData.append('fullname', this.careerForm.get('name').value);
        formData.append('email_address', this.careerForm.get('email').value);
        formData.append('phone_number', this.careerForm.get('phone').value);
        formData.append('qualification', this.careerForm.get('qualification').value);
        formData.append('experience', this.careerForm.get('experience').value);
        formData.append('apply_for', this.careerForm.get('position').value);
        formData.append('resume', this.careerForm.get('profile').value); 
        formData.append('security_code', this.careerForm.get('code').value);
        

	   this.careerService.postcareerService(formData).pipe(first()).subscribe((res:any) => {    
		 
		  if (res.status == "true") {
		  	this.loading = '';
			this.carRes = res.message;
			const thiss = this;
	        setTimeout(function(){ 
	         thiss.carRes = false;
	         }, 6000);
			
		  }
		  
		});  
     
        
    }
    
  loadourcareerService() {
    this.careerService.getcareersService().pipe(first()).subscribe((res: any) => {
      if (res.status == "true") {
        this.careerRes = res.data;
        this.loader = false; /* Loader */
      }
    });
  }
  
   generate_code(){
		
	let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789";
	const lengthOfCode = 4;
	
	 let text = "";
	  for (let i = 0; i < lengthOfCode; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	  }
		
		
	 this.code = text;
	 localStorage.setItem('code', this.code);
	
		
		$('.vefication_codebx').html(this.code);
    }

}
