import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { CompanyComponent } from './components/company/company.component';
import { OurPartnersComponent } from './components/company/our-partners/our-partners.component';
import { CareersComponent } from './components/company/careers/careers.component';
import { ServicesComponent } from './components/services/services.component';
import { WebDesignComponent } from './components/services/web-design/web-design.component';
import { WebApplicationDevelopmentComponent } from './components/services/web-application-development/web-application-development.component';
import { MobileApplicationDevelopmentComponent } from './components/services/mobile-application-development/mobile-application-development.component';
import { EcommerceDevelopmentComponent } from './components/services/ecommerce-development/ecommerce-development.component';
import { DigitalMarketingComponent } from './components/services/digital-marketing/digital-marketing.component';
import { ArtificialIntelligenceComponent } from './components/services/artificial-intelligence/artificial-intelligence.component';
import { BlockChainComponent } from './components/services/block-chain/block-chain.component';
import { DedicatedDevelopmentTeamComponent } from './components/services/dedicated-development-team/dedicated-development-team.component';
import { LeadGenerationComponent } from './components/services/lead-generation/lead-generation.component';
import { ProductDevelopmentComponent } from './components/services/product-development/product-development.component';
import { IndustryComponent } from './components/industry/industry.component';
import { EducationComponent } from './components/industry/education/education.component';
import { FinancialServiceComponent } from './components/industry/financial-service/financial-service.component';
import { FoodBeveragesComponent } from './components/industry/food-beverages/food-beverages.component';
import { HealthcareComponent } from './components/industry/healthcare/healthcare.component';
import { HotelComponent } from './components/industry/hotel/hotel.component';
import { ManufacturingComponent } from './components/industry/manufacturing/manufacturing.component';
import { RetailComponent } from './components/industry/retail/retail.component';
import { SupplyChainLogisticsComponent } from './components/industry/supply-chain-logistics/supply-chain-logistics.component';
import { TransportComponent } from './components/industry/transport/transport.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { StyleComponent } from './components/style/style.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,  
  },
  {
    path: 'home',
    redirectTo: '', 
    pathMatch: 'full' 
  },
  {
    path: 'company',
    component: CompanyComponent,  
  },
  {
    path: 'company/our-partners',
    component: OurPartnersComponent,  
  },
  {
    path: 'company/careers',
    component: CareersComponent,  
  },
  {
    path: 'services',
    component: ServicesComponent,  
  },
  {
    path: 'services/web-design',
    component: WebDesignComponent,  
  },
  {
    path: 'services/web-application-development',
    component: WebApplicationDevelopmentComponent,  
  },
  {
    path: 'services/mobile-application-development',
    component: MobileApplicationDevelopmentComponent,  
  },
  {
    path: 'services/ecommerce-development',
    component: EcommerceDevelopmentComponent,  
  },
  {
    path: 'services/digital-marketing',
    component: DigitalMarketingComponent,  
  },
  {
    path: 'services/artificial-intelligence',
    component: ArtificialIntelligenceComponent,  
  },
  {
    path: 'services/block-chain',
    component: BlockChainComponent,  
  },
  {
    path: 'services/dedicated-development-team',
    component: DedicatedDevelopmentTeamComponent,  
  },
  {
    path: 'services/lead-generation',
    component: LeadGenerationComponent,  
  },
  {
    path: 'services/product-development',
    component: ProductDevelopmentComponent,  
  },
  {
    path: 'industries',
    component: IndustryComponent,  
  },
  {
    path: 'industries/education',
    component: EducationComponent,  
  },
  {
    path: 'industries/financial-service',
    component: FinancialServiceComponent,  
  },
  {
    path: 'industries/food-beverages',
    component: FoodBeveragesComponent,  
  },
  {
    path: 'industries/healthcare',
    component: HealthcareComponent,  
  },
  {
    path: 'industries/hotel',
    component: HotelComponent,  
  },
  {
    path: 'industries/manufacturing',
    component: ManufacturingComponent,  
  },
  {
    path: 'industries/retail',
    component: RetailComponent,  
  },
  {
    path: 'industries/supply-chain-logistics',
    component: SupplyChainLogisticsComponent,  
  },
  {
    path: 'industries/transport',
    component: TransportComponent,  
  },
  {
    path: 'blog',
    component: BlogComponent,  
  },
  {
    path: 'blog/:blogPageid',
    component: BlogDetailComponent,  
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,  
  },
  {
    path: 'sitemap',
    component: SitemapComponent,  
  },
  {
    path: 'style',
    component: StyleComponent,  
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
