import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class CareersService {
    constructor(private http: HttpClient) { }

    getcareersService() {
        return this.http.get<any[]>(`${environment.apiUrl}careers_page_detail`);
    }    
    
    postcareerService(formdata : any) {
    
		return this.http.post(`${environment.apiUrl}careers`, formdata);
        
    }  
	
       
}
