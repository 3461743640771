import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class GetaquoteService {
    constructor(private http: HttpClient) { }

    postgetaquoteService(quote: any) {
        //return this.http.get<any[]>(`${environment.apiUrl}header`);
        
        return this.http.post(`${environment.apiUrl}getaquote`, quote);
    }    
	
     
}
