import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WebdesignService {
    constructor(private http: HttpClient) { }

    getwebdesignService() {
        return this.http.get<any[]>(`${environment.apiUrl}webdesign`);
    }    	    
}
