import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'K2B-Solutions';
  currentRoute = "";
  step = "";
  constructor(private router: Router, private location: Location) { 
  	router.events.subscribe((val) => {
      if(location.path() != ''){
        this.currentRoute = location.path();
        this.step =  "step2";
      } else {
        this.currentRoute = ''
        this.step =  "step1";
      }      
    });               
  }

  onActivate(event) {
    window.scroll(0,0);
  }

}
