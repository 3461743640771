import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class EcommdevService {

    constructor(private http: HttpClient) { }

    getecomdevService() {
        return this.http.get<any[]>(`${environment.apiUrl}ecommerce-development`);
    }    	    
}
