import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(matchingControlName: string, pcode : any) {
    return (formGroup: FormGroup) => {
        //const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
		
		pcode = localStorage.getItem('code');
		
	
        // set error on matchingControl if validation fails
        if (pcode !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
            //$('.vefication_loadimg').hide();
        }
    }
}
