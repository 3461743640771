import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class BlogListService {
    constructor(private http: HttpClient) { }    
    
    getBlogListService(pageid = '') {
        return this.http.get<any[]>(`${environment.apiUrl}blogs?page=`+pageid);
    }
    
    getBlogSearchService(searchkey = '') {
        return this.http.get<any[]>(`${environment.apiUrl}blog-search?s=`+searchkey);
    }

    getBlogDetailService(blogPageid = '') {
        return this.http.get<any[]>(`${environment.apiUrl}blog?slug=`+blogPageid);
    }
    
    getBlogArchivesService(blogPostdate = '') {
        return this.http.get<any[]>(`${environment.apiUrl}blog?date=`+blogPostdate);        
    }
	
     
}
