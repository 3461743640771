import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class ContactusService {

    constructor(private http: HttpClient) { }

    getcontactusService() {
    
        return this.http.get<any[]>(`${environment.apiUrl}contactus_page_detail`);
    } 
    
    postcontactusService(formdata : any) {
    
		return this.http.post(`${environment.apiUrl}contact-us`, formdata);
        
    }    
	
     
}
