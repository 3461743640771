import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WebapplicationdevService {
    constructor(private http: HttpClient) { }

    getwebapplicationService() {
        return this.http.get<any[]>(`${environment.apiUrl}web-application-development`);
    }    	    
} 
