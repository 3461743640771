import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FoodbeverageService { 

    constructor(private http: HttpClient) { }

    getfoodbeverageService() {
        return this.http.get<any[]>(`${environment.apiUrl}get_food_and_beverage`);
    }    	    
}
