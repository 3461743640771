import { Component, OnInit } from '@angular/core';
import { IndustryService } from '../../_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.scss']
})
export class IndustryComponent implements OnInit {

	industryRes : any;

  constructor(private industryService: IndustryService) { }

  ngOnInit() {
  this.loadindustryService();
  }
  
   loadindustryService() {
    this.industryService.getindustryService().pipe(first()).subscribe((res: any) => {
      if (res.status == "true") {
        this.industryRes = res.data;
      }
    });
  }
  

}
