import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LeadgenerationService { 

    constructor(private http: HttpClient) { }

    getleadgeneationService() {
        return this.http.get<any[]>(`${environment.apiUrl}get_lead_generation`);
    }    	    
    
}
