import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class MobileappdevService {
    constructor(private http: HttpClient) { }

    getmobileapplicationService() {
        return this.http.get<any[]>(`${environment.apiUrl}mobile-application-development`);
    }    	    
} 
