import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class HealthcareService { 

    constructor(private http: HttpClient) { }

    gethealthcareService() {
        return this.http.get<any[]>(`${environment.apiUrl}get_healthcare`);
    }    	    
}
