import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class IndustryService {
    constructor(private http: HttpClient) { }

    getindustryService() {
        return this.http.get<any[]>(`${environment.apiUrl}industry_page_detail`);
    }    
	
     
}
