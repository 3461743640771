import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class HomeService1 {
    constructor(private http: HttpClient) { }

    getHomeService() {
        return this.http.get<any[]>(`${environment.apiUrl}homepage`);
    }    
	
     
}