import { Component, OnInit } from "@angular/core";
import { Title ,Meta} from '@angular/platform-browser'; 
import { HomeService1 } from "../../_services";
import { first } from "rxjs/operators";
import * as $ from 'jquery';
import AOS from "aos";
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
AOS.init({
  delay: 100
});

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  homeRes: any;
  sliderArray: any = ["Support", "PMS", "text"];
  loader = true; /* Loader */
  delayTArray: any = ["200", "400", "600", "800"];
  clients: any = [];
    
  constructor(private homeService: HomeService1, private meta:Meta, private titleService: Title){

   this.meta.addTags([
    { name: 'og:locale', content: 'en_US' },
    { name: 'og:title', content: 'K2B SOLUTIONS - Web Design &amp; Mobile Apps Developement' },
    { name: 'og:og:image', content: '/assets/images/logo_k2b.png' },
    { name: 'og:url', content: 'http://192.168.2.113:4200/' },
    { name: 'og:type', content: 'website' },
    { name: 'og:description', content: 'K2B Solutions is a leading web design and mobile apps development company in india. They offers web and mobile apps development, digital marketing services at very affordable cost.' }

    ]);
   
       /* this.meta.updateTag({ name: 'og:locale', content: 'en_US' });
        this.meta.updateTag({ name: 'og:title', content: 'K2B SOLUTIONS - Web Design &amp; Mobile Apps Developement' });
        this.meta.updateTag({ name: 'og:url', content: 'http://192.168.2.113:4200/' });
        this.meta.updateTag({ name: 'og:type', content: 'website' });
        this.meta.updateTag({ name: 'og:description', content: 'K2B Solutions is a leading web design and mobile apps development company in india. They offers web and mobile apps development, digital marketing services at very affordable cost.' });*/
                
      }

      public setTitle( newTitle: string) {
        this.titleService.setTitle( newTitle );
      }

      ngOnInit() {

        this.loadHomeService();
        /*Banner text animation*/

        /*interactivity*/
        function followMouse() {
          let left = 0;
          let top = 0;
          $(document).mousemove(function(event) {
            left = Math.ceil(event.pageX / 80);
            top = Math.ceil(event.pageY / 80);

            $(".mouse_follow").css({
              transform: "translate(" + left + "px," + top + "px)"
            });
            $(".mouse_follow_left").css({
              transform: "translateX(" + left + "px)"
            });
            $(".mouse_follow_top").css({
              transform: "translateY(" + left + "px)"
            });
          });
        }
        $(document).ready(function () {
          followMouse();
        })
        /*interactivity*/

      }
      test(i) {
        return this.delayTArray[i];
      }

      loadHomeService() {
        this.homeService
        .getHomeService()
        .pipe(first())
        .subscribe((res: any) => {
          if (res.status == "true") {
            this.homeRes = res.data; 
            this.loader = false; /* Loader */

            let i = 200;
            this.homeRes.home_page_client_details.forEach(client => {
              if (i > 800) {
                i = 200;
              }
              client.delay = i;
              this.clients.push(client);
              i = i + 200;
            });

            var words = (function () {
              var words = [
              "SME's",
              "Enterprises",
              "Startups"
              ],
              el = document.querySelector(".verb"),
              currentIndex,
              currentWord,
              prevWord,
              duration = 2500;

              var _getIndex = function (max, min) {
                currentIndex = Math.floor(Math.random() * (max - min + 1)) + min;

              //Generates a random number between beginning and end of words array
              return currentIndex;
            };

            var _getWord = function (index) {
              currentWord = words[index];

              return currentWord;
            };

            var _clear = function () {
              setTimeout(function () {
                el.className = "verb";

              }, duration / 4);
            };

            var _toggleWord = function (duration) {
              setInterval(function () {

                //Stores value of previous word
                prevWord = currentWord;

                //Generate new current word
                currentWord = words[_getIndex(words.length - 1, 0)];

                //Generate new word if prev matches current
                if (prevWord === currentWord) {
                  currentWord = words[_getIndex(words.length - 1, 0)];
                }

                //Swap new value
                el.innerHTML = currentWord;
                //document.getElementById('hello').innerHTML = 'hi';
                //document.getElementById('hello').innerHTML = currentWord
                // el.innerHTML = currentWord;

                //Clear class styles
                _clear();

                //Fade in word
                el.classList.add("js-block", "js-fade-in-verb");

              }, duration);
            };

            var _init = function () {
              _toggleWord(duration);
            };

            //Public API
            return {
              init: function () {
                _init();
              }
            };
          })();

          words.init();
        }
      });
      }


      enquery_PopupToggle() {

        if ($('.QukEnqPop').hasClass('open')) {

          $('.QukEnqPop').removeClass('open');

        }
        else{

          $('.QukEnqPop').toggleClass('open');
        }
      }


    }
