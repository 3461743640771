import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SupplychainService { 

    constructor(private http: HttpClient) { }

    getsupplychainService() {
        return this.http.get<any[]>(`${environment.apiUrl}get_supply_chain_logistics`);
    }    	    
}
