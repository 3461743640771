export * from './home.service';
export * from './header.service';
export * from './footer.service';
export * from './blog-filter.service';
export * from './blog-list.service';
export * from './company.service';
export * from './servicespage.service';
export * from './webdesign.service';
export * from './web_app_dev.service';
export * from './mobile_app_dev.service';
export * from './ecommerce_dev.service';
export * from './our_partners.service';
export * from './digital_market.service';
export * from './getaquote.service';
export * from './contactus.service';
export * from './careers.service';
export * from './industry.service';
export * from './ai.service';
export * from './blockchain.service';
export * from './dedicated_dev_team.service';
export * from './lead_generation.service';
export * from './product_development.service';
export * from './manufacturing.service';
export * from './supply_chain.service';
export * from './healthcare.service';
export * from './retail.service';
export * from './education.service';
export * from './food_and_beverage.service';
export * from './financial.service'; 
export * from './transport.service'; 
export * from './hotel.service'; 

