import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class companyService {
    constructor(private http: HttpClient) { }

    getcompanyService() {
        return this.http.get<any[]>(`${environment.apiUrl}company`);
    }    
	
     
}
