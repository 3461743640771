import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactusService } from '../../_services';
import { first } from 'rxjs/operators';
import * as $ from 'jquery';

import { MustMatch } from '../../_helpers/must-match.validator';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {  

  registerForm: FormGroup;
  submitted = false;
  contactusRes : any;
  loader = true; /* Loader */
  contactRes : any;
  // code : any;    
  loading : any;

  @ViewChild('recaptcha', {static: false }) recaptchaElement: ElementRef;

    constructor(private formBuilder: FormBuilder,private contactusserv :  ContactusService) { }

    ngOnInit() {
      /*interactivity*/
        function followMouse() {
          let left = 0;
          let top = 0;
          $(document).mousemove(function(event) {
            left = Math.ceil(event.pageX / 80);
            top = Math.ceil(event.pageY / 80);

            $(".mouse_follow").css({
              transform: "translate(" + left + "px," + top + "px)"
            });
            $(".mouse_follow_left").css({
              transform: "translateX(" + left + "px)"
            });
            $(".mouse_follow_top").css({
              transform: "translateY(" + left + "px)"
            });
          });
        }
        $(document).ready(function () {
          followMouse();
        })
        /*interactivity*/
    
      // this.generate_code();
    
      // const pcode = localStorage.getItem('code');
       
      this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],      
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/), Validators.min(8)]],
        message: [''],
        code: ['',Validators.required]
      /* }, {
        validator: MustMatch('code',pcode) */
      });

      this.loadcontactus(); 
    }

    addRecaptchaScript() { 
      window['grecaptchaCallback'] = () => {
        this.renderReCaptcha();
      }     
      (function(d, s, id, obj){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { obj.renderReCaptcha(); return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'recaptcha-jssdk', this));     
    }
    
    loadcontactus() {
      this.contactusserv.getcontactusService().pipe(first()).subscribe((res: any) => {
        if (res.status == "true") {
          this.contactusRes = res.data;
          this.loader = false; /* Loader */
          this.addRecaptchaScript();
        }
      });
    }

    renderReCaptcha() {
      window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
        'sitekey' : '6Leq3OEUAAAAACqFkyVqcBXL5UrU8jrYZMEl6Yyb',
        'callback': (response) => {
          this.registerForm.get("code").setValue(response);
        }
      });
    }
    
    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit1() {

      this.loading = 'load_btn';
      this.submitted = true;

      // stop here if form is invalid
      if (this.registerForm.invalid) { 
        const thiss = this;
        setTimeout(function(){ 
         thiss.loading = '';
         }, 800);                   
          return;            
      }        
        
      const formData = new FormData();
      formData.append('fullname', this.registerForm.get('name').value);
      formData.append('email_address', this.registerForm.get('email').value);
      formData.append('phone_number', this.registerForm.get('phone').value);
      formData.append('description_job', this.registerForm.get('message').value);
      formData.append('security_code', this.registerForm.get('code').value);

      this.contactusserv.postcontactusService(formData).pipe(first()).subscribe((res:any) => {    
        if (res.status == "true") {
          this.loading = '';
          this.contactRes = res.message;
          //this.registerForm.reset();
          const thiss = this;
          setTimeout(function(){ 
           thiss.contactRes = false;
           }, 5000);
        }
      });
    }
    
  /*generate_code(){  
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789";
    const lengthOfCode = 4;
  
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    
    this.code = text;
    localStorage.setItem('code', this.code); 
    $('.vefication_codebx').html(this.code);
  }*/

}
