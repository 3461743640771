import { Component, OnInit } from '@angular/core';
import { OurpartnersService } from '../../../_services';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-our-partners',
  templateUrl: './our-partners.component.html',
  styleUrls: ['./our-partners.component.scss']
})
export class OurPartnersComponent implements OnInit {
	
	ourpartnersRes : any;
  loader = true; /* Loader */

  constructor(private ourpartnerService: OurpartnersService) { }

  ngOnInit() {
	this.loadourpartnerService();
  }
  
  loadourpartnerService() {
    this.ourpartnerService.getourpartnerService().pipe(first()).subscribe((res: any) => {
      if (res.status == "true") {
        this.ourpartnersRes = res.data;
        this.loader = false; /* Loader */
      }
    });
  }
   

}
