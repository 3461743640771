import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; // HttpClientModule - For Api call, HTTP_INTERCEPTORS - For Api authontication
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { JwtInterceptor } from './_helpers'; // For Api authontication
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { StyleComponent } from './components/style/style.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CompanyComponent } from './components/company/company.component';
import { ServicesComponent } from './components/services/services.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { WebDesignComponent } from './components/services/web-design/web-design.component';
import { WebApplicationDevelopmentComponent } from './components/services/web-application-development/web-application-development.component';
import { MobileApplicationDevelopmentComponent } from './components/services/mobile-application-development/mobile-application-development.component';
import { EcommerceDevelopmentComponent } from './components/services/ecommerce-development/ecommerce-development.component';
import { DigitalMarketingComponent } from './components/services/digital-marketing/digital-marketing.component';
import { OurPartnersComponent } from './components/company/our-partners/our-partners.component';
import { CareersComponent } from './components/company/careers/careers.component';
import { GetQuoteComponent } from './components/get-quote/get-quote.component';
import { ArtificialIntelligenceComponent } from './components/services/artificial-intelligence/artificial-intelligence.component';
import { BlockChainComponent } from './components/services/block-chain/block-chain.component';
import { DedicatedDevelopmentTeamComponent } from './components/services/dedicated-development-team/dedicated-development-team.component';
import { LeadGenerationComponent } from './components/services/lead-generation/lead-generation.component';
import { ProductDevelopmentComponent } from './components/services/product-development/product-development.component';
import { IndustryComponent } from './components/industry/industry.component';
import { EducationComponent } from './components/industry/education/education.component';
import { FinancialServiceComponent } from './components/industry/financial-service/financial-service.component';
import { FoodBeveragesComponent } from './components/industry/food-beverages/food-beverages.component';
import { HealthcareComponent } from './components/industry/healthcare/healthcare.component';
import { HotelComponent } from './components/industry/hotel/hotel.component';
import { ManufacturingComponent } from './components/industry/manufacturing/manufacturing.component';
import { RetailComponent } from './components/industry/retail/retail.component';
import { SupplyChainLogisticsComponent } from './components/industry/supply-chain-logistics/supply-chain-logistics.component';
import { TransportComponent } from './components/industry/transport/transport.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { ParticlesModule } from 'angular-particle';
import { LoaderComponent } from './components/loader/loader.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

 
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    StyleComponent,
    ContactUsComponent,
    CompanyComponent,
    ServicesComponent,
    BlogComponent,
    BlogDetailComponent,
    WebDesignComponent,
    WebApplicationDevelopmentComponent,
    MobileApplicationDevelopmentComponent,
    EcommerceDevelopmentComponent,
    DigitalMarketingComponent,
    OurPartnersComponent,
    CareersComponent,
    GetQuoteComponent,
    ArtificialIntelligenceComponent,
    BlockChainComponent,
    DedicatedDevelopmentTeamComponent,
    LeadGenerationComponent,
    ProductDevelopmentComponent,
    IndustryComponent,
    EducationComponent,
    FinancialServiceComponent,
    FoodBeveragesComponent,
    HealthcareComponent,
    HotelComponent,
    ManufacturingComponent,
    RetailComponent,
    SupplyChainLogisticsComponent,
    TransportComponent,
    SitemapComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ParticlesModule,
    InfiniteScrollModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
