import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class OurpartnersService {
    constructor(private http: HttpClient) { }

    getourpartnerService() {
        return this.http.get<any[]>(`${environment.apiUrl}our-partners`);
    }    
	
       
}
